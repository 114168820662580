import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import TextComponent from './Texts.js';

function Overlay() {

    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate3d(-50%, -50%, 0)' }} >
        <TextComponent  />
      </div>
      
    )
  }

ReactDOM.render(
    <>
    <div class="overlay">
        <Overlay />
    </div>
    </>,
    document.getElementById('root')
)
