import React from 'react';
import './style.css';

export function changeId () {
    document.getElementById("books").style.display = "block";
 }

function close () {
  document.getElementById("books").style.display = "none";
}

function open () {
  if (document.getElementById("btn-credit").textContent === "credits") {
  document.getElementById("menu").style.display = "none";
  document.getElementById("creditos").style.display = "block";
  document.getElementById("btn-credit").textContent = "back";
} else {
  document.getElementById("menu").style.display = "flex";
  document.getElementById("creditos").style.display = "none";
  document.getElementById("btn-credit").textContent = "credits";
}
}

function pt () {
  document.getElementById("img-brasil").style.opacity = 1;
}

function en () {
  document.getElementById("img-brasil").style.opacity = 1;
  document.getElementById("img-eua").style.opacity = 1;
}

function out () {
  document.getElementById("img-brasil").style.opacity = 0;
  document.getElementById("img-eua").style.opacity = 0;
}

const TextComponent = () => {

  return (
    <div class="container" >
    
    <div id="books">
    <div class="bandeiras">
    <img src="icons/brasil.png" alt="icon" id="img-brasil"></img>
    <img src="icons/eua.png" alt="icon" id="img-eua"></img>
    </div>
    <button class="btn-ask" onClick={close}>X</button>
      <div class="books-menu" id="menu">
          <div class="item-books en" id="en1" onMouseOver={en} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B07KQF9VQX/ref=dbs_a_def_rwt_bibl_vppi_i3" target='_blank'>
              <img src="books/2_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/2.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books pt" id="pt1" onMouseOver={pt} onMouseOut={out}>
          <a href="http://apassarinhologa.com.br/serie-aves-da-minha-cidade/" target='_blank'>
              <img src="books/4_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/4.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books en" id="en2" onMouseOver={en} onMouseOut={out}>
            <a href="https://www.amazon.com.br/gp/product/B01BFNCN6Y/ref=dbs_a_def_rwt_bibl_vppi_i2" target='_blank'>
              <img src="books/1_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/1.jpg" alt="books" class="img-top"></img>
            </a>
          </div><div class="item-books pt" id="pt2" onMouseOver={pt} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B07Z4BXXXQ/ref=dbs_a_def_rwt_bibl_vppi_i1" target='_blank'>
              <img src="books/3_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/3.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books pt" id="pt3" onMouseOver={pt} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B09MNRMDRD/ref=dbs_a_def_rwt_bibl_vppi_i0" target='_blank'>
              <img src="books/5_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/5.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books pt" id="pt4" onMouseOver={pt} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B09MNQHSY8/ref=dbs_a_def_rwt_bibl_vppi_i6" target='_blank'>
              <img src="books/6_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/6.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books pt" id="pt5" onMouseOver={pt} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B09MNRCWB1/ref=dbs_a_def_rwt_bibl_vppi_i4" target='_blank'>
              <img src="books/7_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/7.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books pt" id="pt6" onMouseOver={pt} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B09MNR5RY7/ref=dbs_a_def_rwt_bibl_vppi_i5" target='_blank'>
              <img src="books/8_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/8.jpg" alt="books" class="img-top"></img>
          </a>
          </div><div class="item-books pt" id="pt7" onMouseOver={pt} onMouseOut={out}>
          <a href="https://www.amazon.com.br/gp/product/B09MNQC27C/ref=dbs_a_def_rwt_bibl_vppi_i7" target='_blank'>
              <img src="books/9_2.jpg" alt="books" class="img-bottom"></img>
              <img src="books/9.jpg" alt="books" class="img-top"></img>
          </a>
          </div>
          </div>
    <div id="creditos">
      <p> Developed in React Fiber / Three.js by Victor Allenspach with the help of many YouTube tutorials, Github repositories and a little AI.</p>
      <br></br>
      <p>The stunning background images were generated in <a href="https://skybox.blockadelabs.com/" target='_blank' id="skybox">https://skybox.blockadelabs.com/</a>.</p>

    </div>

    <div id="medium">
    <a href="https://medium.com/@victorallenspach" target='_blank'>
      <img src="icons/medium.png" alt="icon" class="img-medium"></img>
    </a>
    </div><div id="links">
    <a href="https://www.instagram.com/vallenspach.art/" target='_blank'>
      <img src="icons/instagram.png" alt="icon" class="img-icons"></img>
    </a>
    <a href="https://www.youtube.com/channel/UCIJioE5BE_hZDmIOxfeHPCg" target='_blank'>
      <img src="icons/youtube.png" alt="icon" class="img-icons"></img>
    </a>
    <a href="https://www.goodreads.com/author/show/15109626.Victor_Allenspach" target='_blank'>
      <img src="icons/goodreads.png" alt="icon" class="img-icons"></img>
    </a>
    <a href="https://www.skoob.com.br/autor/15542-victor-allenspach" target='_blank'>
      <img src="icons/skoob.png" alt="icon" class="img-icons"></img>
    </a>
    <a href="https://twitter.com/v_allenspach?lang=pt-br" target='_blank'>
      <img src="icons/twitter.png" alt="icon" class="img-icons"></img>
    </a>
    <a href="https://www.facebook.com/victor.allenspach/" target='_blank'>
      <img src="icons/facebook.png" alt="icon" class="img-icons"></img>
    </a>
    </div>
    <div id="credits">
    <button class="btn-credit" id="btn-credit" onClick={open}>credits</button>
    </div>
</div></div>
  );
};
export default TextComponent;
